import { createActionGroup, props } from '@ngrx/store';
import { DialerQueue } from 'src/app/shared/models';
import { DialerQueueChanges } from 'src/app/shared/models/classes/call';

export const QueueActions = createActionGroup({
  source: 'Queue',
  events: {
    'Load Queues': props<{ agencyId: string | string[] }>(),
    'Load Queues Success': props<{ queues: DialerQueue[] }>(),
    'Load Queues Failure': props<{ error: any }>(),
    'Load Queue': props<{ queueId: string; agencyId: string }>(),
    'Load Queue Success': props<{ queue: DialerQueue }>(),
    'Load Queue Failure': props<{ error: any }>(),
    'Add Queue': props<{ queue: DialerQueue; agencyId: string; }>(),
    'Add Queue Success': props<{ queue: DialerQueue }>(),
    'Add Queue Failure': props<{ error: any }>(),
    'Remove Queue': props<{ queueId: string; agencyId: string }>(),
    'Remove Queue Success': props<{ queueId: string }>(),
    'Remove Queue Failure': props<{ error: any }>(),
    'Update Queue': props<{
      queueId: string;
      agencyId: string;
      queue: DialerQueue;
      changes?: DialerQueueChanges
    }>(),
    'Update Queue Success': props<{
      queueId: string;
      agencyId: string;
      queue: DialerQueue;
    }>(),
    'Update Queue Failure': props<{ error: any }>(),
  },
});
